import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import EntrepreneurImage from '../../assets/images/entrepeneur.webp';
import CalendlyPopupButton from "../calendly/calendly";
import './goldenvisa.css';

const GoldenVisa = () => {
    const [isVisible, setIsVisible] = useState({
        intro: false,
        card1: false,
        card2: false,
        card3: false,
        testimonials: false,
        benefits: false,
        entrepreneur: false,
        faq: false
    });

    const benefitsRef = useRef(null);
    const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

    useEffect(() => {
        const handleScroll = () => {
            const elements = {
                intro: document.querySelector('.golden-intro'),
                card1: document.querySelector('.golden-card-1'),
                card2: document.querySelector('.golden-card-2'),
                card3: document.querySelector('.golden-card-3'),
                testimonials: document.querySelector('.golden-testimonials'),
                benefits: document.querySelector('.golden-benefits'),
                entrepreneur: document.querySelector('.golden-entrepreneur'),
                faq: document.querySelector('.golden-faq')
            };

            Object.entries(elements).forEach(([key, element]) => {
                if (element) {
                    const position = element.getBoundingClientRect();
                    const isVisible = position.top < window.innerHeight - 100;
                    setIsVisible(prev => ({ ...prev, [key]: isVisible }));
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        // Trigger once on load
        setTimeout(handleScroll, 500);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const testimonials = [
        {
            name: "Carlos Rodríguez",
            role: "Empresario Tecnológico",
            content: "Gracias a Diego, el proceso para obtener mi Golden Visa fue mucho más sencillo de lo que esperaba. Su conocimiento del mercado inmobiliario en Dubái es excepcional.",
            image: "https://res.cloudinary.com/dm9pjru06/image/upload/v1725581720/testimonial-1_bpzqkw.webp"
        },
        {
            name: "Maria González",
            role: "Inversionista Internacional",
            content: "Invertir en Dubái cambió completamente mi panorama financiero. Diego me guió paso a paso, desde la selección de propiedades hasta la obtención de mi residencia.",
            image: "https://res.cloudinary.com/dm9pjru06/image/upload/v1725581720/testimonial-2_wmuakk.webp"
        },
        {
            name: "Juan Méndez",
            role: "Empresario",
            content: "La Golden Visa ha abierto innumerables puertas para mi familia y mis negocios. El asesoramiento de Diego fue crucial para elegir la mejor opción de inversión.",
            image: "https://res.cloudinary.com/dm9pjru06/image/upload/v1725581720/testimonial-3_qafwdm.webp"
        }
    ];

    const benefits = [
        {
            title: "Residencia de Larga Duración",
            desc: "Obtén visas de 5 a 10 años con renovación automática, brindando estabilidad para ti y tu familia.",
            icon: "fa-home"
        },
        {
            title: "0% de Impuestos",
            desc: "Disfruta de un régimen fiscal privilegiado sin impuestos sobre la renta personal, ganancias de capital o herencias.",
            icon: "fa-percentage"
        },
        {
            title: "Educación de Élite",
            desc: "Acceso a instituciones educativas de primer nivel para tus hijos, con oportunidades internacionales.",
            icon: "fa-graduation-cap"
        },
        {
            title: "Seguridad y Estabilidad",
            desc: "Dubái es una de las ciudades más seguras del mundo, con una economía estable y en constante crecimiento.",
            icon: "fa-shield-alt"
        }
    ];

    const faqs = [
        {
            question: "¿Cuánto tiempo tarda el proceso de obtención de la Golden Visa?",
            answer: "El proceso completo suele tomar entre 3 y 6 semanas desde la presentación de todos los documentos requeridos hasta la emisión de la visa, dependiendo de la categoría de aplicación y la completitud de la documentación."
        },
        {
            question: "¿Cuál es la inversión mínima para calificar para la Golden Visa?",
            answer: "La inversión mínima varía según la categoría. Para inversiones inmobiliarias, comienza desde 1 millón de AED (aproximadamente 272,000 USD) para propiedades finalizadas, mientras que para emprendedores e inversores de negocios existen otras opciones con diferentes umbrales."
        },
        {
            question: "¿Necesito vivir en Dubái para mantener mi Golden Visa?",
            answer: "No, una de las grandes ventajas de la Golden Visa es que no existe requisito mínimo de residencia. Puedes mantener tu estatus de residente sin necesidad de estar físicamente en Dubái durante períodos específicos."
        }
    ];

    return (
        <div className="golden-container">
            <Helmet>
                <title>Golden Visa Dubái 2025 | Residencia Permanente por Inversión | Diego Carranza</title>
                <meta name="description" content="Obtén la Golden Visa o residencia permanente en Dubái a través de inversiones inmobiliarias o empresariales. Asesoramiento personalizado con Diego Carranza, experto en inversiones en EAU." />
                <meta name="keywords" content="Golden Visa Dubái, residencia Dubái, inversión inmobiliaria Dubái, visa de inversionista EAU, residencia permanente Emiratos Árabes Unidos" />
                <link rel="canonical" href="https://inversionesdubai.com/golden-visa" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Golden Visa Dubái 2025 | Residencia Permanente por Inversión" />
                <meta property="og:description" content="Asegura tu futuro y el de tu familia con la Golden Visa de Dubái. Asesoramiento personalizado en inversiones inmobiliarias y empresariales con Diego Carranza." />
                <meta property="og:image" content="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" />
                <meta property="og:url" content="https://inversionesdubai.com/golden-visa" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" crossOrigin="anonymous" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
            </Helmet>

            <section className="golden-hero">
                <div className="golden-hero-overlay">
                    <span className="golden-exclusive-label">EXCLUSIVO 2025</span>
                    <h2 className="golden-hero-title" style={{ letterSpacing: "-0.06em" }}>
                        TRANSFORME SU FUTURO CON LA <span className="golden-accent-text">GOLDEN VISA</span> DE DUBÁI
                    </h2>
                    <p className="golden-hero-subtitle">
                        Residencia exclusiva para inversionistas y profesionales de élite. Asesoramiento personalizado con Diego Carranza, experto en inversiones de alto rendimiento.
                    </p>
                    <div className="golden-hero-stats">
                        <div className="golden-stat">
                            <span className="golden-stat-number">10</span>
                            <span className="golden-stat-text">Años de Residencia</span>
                        </div>
                        <div className="golden-stat">
                            <span className="golden-stat-number">0%</span>
                            <span className="golden-stat-text">Impuestos</span>
                        </div>
                        <div className="golden-stat">
                            <span className="golden-stat-number">100%</span>
                            <span className="golden-stat-text">Propiedad Extranjera</span>
                        </div>
                    </div>
                    <div className="golden-cta-container">
                        <CalendlyPopupButton />
                        <button onClick={() => scrollToRef(benefitsRef)} className="golden-button golden-button-secondary">
                            DESCUBRIR BENEFICIOS
                        </button>
                    </div>
                </div>
            </section>

            <section className={`golden-intro ${isVisible.intro ? 'animate-fade-in' : ''}`}>
                <div className="golden-intro-content">
                    <span className="golden-exclusive-tag">ACCESO PRIVILEGIADO</span>
                    <h1 className="golden-intro-title" style={{ letterSpacing: "-0.06em" }}>
                        EL CAMINO HACIA LA <span className="golden-accent-text">RESIDENCIA ELITE</span> EN UNO DE LOS DESTINOS MÁS EXCLUSIVOS DEL MUNDO
                    </h1>
                    <div className="golden-divider"></div>
                    <p className="golden-intro-description">
                        La Golden Visa de Dubái representa más que un permiso de residencia—es la llave hacia un estilo de vida privilegiado, oportunidades de inversión excepcionales y un futuro seguro para usted y su familia.
                    </p>
                </div>
            </section>

            <section className="golden-process">
                <div className="golden-process-content">
                    <h2 className="golden-section-title" style={{ letterSpacing: "-0.06em" }}>
                        PROCESO <span className="golden-accent-text">SIMPLIFICADO</span>
                    </h2>
                    <div className="golden-process-steps">
                        <div className="golden-process-step">
                            <div className="golden-step-number">01</div>
                            <h3 className="golden-step-title">Consulta Estratégica</h3>
                            <p className="golden-step-description">Evaluación personalizada de su situación y objetivos para determinar la mejor ruta hacia su Golden Visa.</p>
                        </div>
                        <div className="golden-process-step">
                            <div className="golden-step-number">02</div>
                            <h3 className="golden-step-title">Selección de Inversión</h3>
                            <p className="golden-step-description">Identificación de oportunidades de inversión premium que califiquen para la Golden Visa y maximicen su retorno.</p>
                        </div>
                        <div className="golden-process-step">
                            <div className="golden-step-number">03</div>
                            <h3 className="golden-step-title">Gestión Documental</h3>
                            <p className="golden-step-description">Preparación y presentación de toda la documentación necesaria con asistencia legal especializada.</p>
                        </div>
                        <div className="golden-process-step">
                            <div className="golden-step-number">04</div>
                            <h3 className="golden-step-title">Obtención de Residencia</h3>
                            <p className="golden-step-description">Finalización del proceso y entrega de su Golden Visa, abriendo las puertas a todos los beneficios de residir en Dubái.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-grid">
                <div className={`golden-card golden-card-1 ${isVisible.card1 ? 'animate-slide-right' : ''}`}>
                    <div className="golden-card-icon">
                        <i className="fas fa-passport"></i>
                    </div>
                    <h2 className="golden-card-title" style={{ letterSpacing: "-0.06em" }}>
                        <span className="golden-accent-text">RESIDENCIA EXCLUSIVA EN DUBÁI</span>
                    </h2>
                    <div className="golden-card-content">
                        <p>La residencia en Dubái representa mucho más que un lujo—es una decisión estratégica para asegurar estabilidad financiera y un futuro próspero para usted y su familia en tiempos de incertidumbre global.</p>
                        <p>El estatus de residente, conocido como <strong className="golden-highlight">«Emiratos ID»</strong>, abre puertas a un ecosistema de oportunidades exclusivas, conexiones de alto nivel y acceso a uno de los centros financieros más dinámicos del mundo.</p>
                        <p>Dubái ofrece un portafolio incomparable de <Link to="/" className="golden-link">oportunidades de inversión premium</Link> con retornos excepcionales. Mi asesoría personalizada le guiará para obtener la <strong className="golden-highlight">«GOLDEN VISA»</strong> mediante inversiones estratégicas, disfrutando de un entorno <Link to="/inversiones-impuestos-dubai" className="golden-link">fiscalmente privilegiado</Link> con 0% de impuestos sobre la renta.</p>
                    </div>
                </div>

                <div className={`golden-card golden-card-2 ${isVisible.card2 ? 'animate-slide-left' : ''}`}>
                    <div className="golden-card-icon">
                        <i className="fas fa-users"></i>
                    </div>
                    <h2 className="golden-card-title" style={{ letterSpacing: "-0.06em" }}>
                        <span className="golden-accent-text">PRIVILEGIOS FAMILIARES EXCLUSIVOS</span>
                    </h2>
                    <div className="golden-card-content">
                        <p>La verdadera distinción de la Golden Visa radica en los beneficios extendidos para su familia. Su cónyuge e hijos dependientes pueden ser incluidos en su solicitud, proporcionando a toda su familia acceso a un estilo de vida privilegiado.</p>
                        <p>Este beneficio transformador asegura que sus seres queridos disfruten de todas las ventajas que Dubái ofrece: desde educación de clase mundial en instituciones internacionales hasta atención médica de primer nivel y un entorno excepcionalmente seguro.</p>
                        <p>Además, su familia tendrá acceso a una red global de oportunidades, creando un legado de prosperidad que trasciende generaciones.</p>
                    </div>
                </div>

                <div className={`golden-card golden-card-3 ${isVisible.card3 ? 'animate-slide-right' : ''}`}>
                    <div className="golden-card-icon">
                        <i className="fas fa-crown"></i>
                    </div>
                    <h2 className="golden-card-title" style={{ letterSpacing: "-0.06em" }}>
                        <span className="golden-accent-text">LA EXCLUSIVIDAD DE LA GOLDEN VISA</span>
                    </h2>
                    <div className="golden-card-content">
                        <p>La <strong className="golden-highlight">Golden Visa</strong> representa el más alto nivel de reconocimiento que otorgan los Emiratos Árabes Unidos a inversores distinguidos, empresarios visionarios, profesionales excepcionales y personas con talentos extraordinarios.</p>
                        <p>Este estatus privilegiado le otorga:
                            <ul className="golden-list">
                                <li>Residencia de larga duración (5-10 años) con renovación automática</li>
                                <li>Libertad de movimiento sin restricciones de permanencia mínima</li>
                                <li>Acceso completo al mercado inmobiliario premium de Dubái</li>
                                <li>Oportunidades de networking con élites globales</li>
                                <li>Entorno empresarial con incentivos fiscales incomparables</li>
                            </ul>
                        </p>
                        <p>Como su asesor personalizado, me encargo de cada detalle del proceso, desde la selección de la inversión ideal hasta la finalización de todos los trámites, asegurando que su transición a este exclusivo estatus sea impecable.</p>
                    </div>
                </div>
            </section>

            <section ref={benefitsRef} className={`golden-benefits ${isVisible.benefits ? 'animate-fade-in' : ''}`}>
                <div className="golden-benefits-content">
                    <h2 className="golden-section-title" style={{ letterSpacing: "-0.06em" }}>
                        BENEFICIOS <span className="golden-accent-text">EXTRAORDINARIOS</span>
                    </h2>
                    <div className="golden-benefits-grid">
                        {benefits.map((benefit, index) => (
                            <div key={index} className="golden-benefit-card">
                                <div className="golden-benefit-icon">
                                    <i className={`fas ${benefit.icon}`}></i>
                                </div>
                                <h3 className="golden-benefit-title">{benefit.title}</h3>
                                <p className="golden-benefit-description">{benefit.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className={`golden-testimonials ${isVisible.testimonials ? 'animate-fade-in' : ''}`}>
                <div className="golden-testimonials-content">
                    <h2 className="golden-section-title" style={{ letterSpacing: "-0.06em" }}>
                        HISTORIAS DE <span className="golden-accent-text">ÉXITO</span>
                    </h2>
                    <div className="golden-testimonials-grid">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="golden-testimonial-card">
                                <div className="golden-testimonial-quote">
                                    <i className="fas fa-quote-left"></i>
                                </div>
                                <p className="golden-testimonial-content">{testimonial.content}</p>
                                <div className="golden-testimonial-author">
                                    
                                    <div className="golden-testimonial-info">
                                        <h3 className="golden-testimonial-name">{testimonial.name}</h3>
                                        <p className="golden-testimonial-role">{testimonial.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className={`golden-entrepreneur ${isVisible.entrepreneur ? 'animate-fade-in' : ''}`}>
                <div className="golden-entrepreneur-content">
                    <div className="golden-entrepreneur-grid">
                        <div className="golden-entrepreneur-image-container">
                            <img src={EntrepreneurImage} alt="Diego Carranza - Experto en inversiones en Dubái" className="golden-entrepreneur-image" />
                            <div className="golden-entrepreneur-credentials">
                                <span className="golden-credential"><i className="fas fa-check-circle"></i> 10+ años de experiencia</span>
                                <span className="golden-credential"><i className="fas fa-check-circle"></i> 300+ clientes satisfechos</span>
                                <span className="golden-credential"><i className="fas fa-check-circle"></i> $50M+ en inversiones</span>
                            </div>
                        </div>
                        <div className="golden-entrepreneur-info">
                            <span className="golden-expert-label">SU ASESOR PERSONAL</span>
                            <h2 className="golden-entrepreneur-name" style={{ letterSpacing: "-0.06em" }}>
                                DIEGO CARRANZA
                            </h2>
                            <p className="golden-entrepreneur-description">
                                Con una trayectoria excepcional guiando a inversionistas de alto nivel, Diego Carranza se ha posicionado como uno de los consultores más respetados en el mercado inmobiliario de lujo y en el proceso de obtención de la Golden Visa en Dubái.
                            </p>
                            <p className="golden-entrepreneur-description">
                                Su profundo conocimiento del mercado local, combinado con su red exclusiva de contactos y su enfoque personalizado, garantiza que cada cliente reciba la asesoría más estratégica y efectiva para maximizar el retorno de su inversión mientras asegura su residencia en este paraíso financiero.
                            </p>
                            <a 
                                href="https://wa.link/rdww5u" 
                                rel="noreferrer" 
                                target="_blank" 
                                className="golden-button golden-button-primary"
                            >
                                CONSULTA PERSONALIZADA
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-cta">
                <div className="golden-cta-content">
                    <span className="golden-limited-offer">OPORTUNIDAD LIMITADA</span>
                    <h3 className="golden-cta-title" style={{ letterSpacing: "-0.06em" }}>
                        ASEGURE SU FUTURO EN EL CENTRO DE OPORTUNIDADES <span className="golden-accent-text">MÁS EXCLUSIVO DEL MUNDO</span>
                    </h3>
                    <p className="golden-cta-text">
                        Cada día, inversionistas visionarios están descubriendo el potencial transformador de la Golden Visa de Dubái. Únase a la élite global que está redefiniendo su futuro en una de las economías más dinámicas y fiscalmente atractivas del mundo.
                    </p>
                    <div className="golden-cta-buttons">
                        <a 
                            href="https://wa.link/rdww5u" 
                            rel="noreferrer" 
                            target="_blank" 
                            className="golden-button golden-button-primary"
                        >
                            AGENDAR CONSULTA PRIVADA
                        </a>
                        <CalendlyPopupButton className="golden-button golden-button-secondary" />
                    </div>
                    <div className="golden-guarantee">
                        <i className="fas fa-shield-alt"></i>
                        <p>Asesoramiento confidencial y personalizado con garantía de satisfacción</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default GoldenVisa;