import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import logo from '../../assets/svg/logo.svg'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const menuRef = useRef(null);

  // Función para detectar si es dispositivo móvil
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const toggleMenu = (index) => {
    if (isMobile) {
      setActiveMenu(activeMenu === index ? null : index);
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setActiveMenu(null);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuItems = [
    {
      id: 1,
      label: "ACERCA DE",
      submenu: [
        { label: "DIEGO CARRANZA", link: "/quien-es-inversiones-dubai" },
        { label: "PUBLICIDAD Y CONTRATACIONES", link: "/trabajar-con-inversiones-dubai" }
      ]
    },
    {
      id: 2,
      label: "SERVICIOS",
      submenu: [
        { label: "INVERSIONES E IMPUESTOS", link: "/inversiones-impuestos-dubai" },
        { label: "OBTENER RESIDENCIA", link: "/obtener-residencia-dubai" },
        { label: "INVERSIONES EN BIENES RAICES", link: "/invertir-bienes-raises-dubai" },
        { label: "EMIGRAR A DUBAI", link: "/emigrar-a-dubai" },
        { label: "INVERTIR EN CRIPTO", link: "/invertir-criptomonedas-dubai" }
      ]
    },
    {
      id: 3,
      label: "PODCAST",
      submenu: [
        { label: "CONTENIDO RECIENTE", link: "/podcast-inversiones-dubai" },
      ]
    },
    {
      id: 4,
      label: "ENTRENA CONMIGO",
      submenu: [
        { label: "MASTERCLASS", link: "/aprende-sobre-inversiones-dubai" }
      ]
    }
  ];

  return (
    <nav className="navbar">
      <Link to="/" className="nav-logo">
        <img src={logo} alt="logo" className="logo"/>
      </Link>
      
      <div ref={menuRef} className="menu-container">
        <button 
          className={`hamburger ${isOpen ? 'active' : ''}`} 
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Menú Principal"
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
        
        <ul className={`menu-list ${isOpen ? 'open' : ''}`}>
          {menuItems.map(item => (
            <li key={item.id} className="menu-item-wrapper">
              <div 
                className={`menu-item ${activeMenu === item.id ? 'active' : ''}`}
                onClick={() => toggleMenu(item.id)}
                onMouseEnter={() => !isMobile && setActiveMenu(item.id)}
                onMouseLeave={() => !isMobile && setActiveMenu(null)}
              >
                <span className="menu-label">{item.label}</span>
                <span className={`menu-arrow ${activeMenu === item.id ? 'open' : ''}`}></span>
              </div>
              
              <ul className={`submenu ${activeMenu === item.id ? 'open' : ''}`}>
                {item.submenu.map((subItem, index) => (
                  <li key={index} className="submenu-item">
                    <Link to={subItem.link} onClick={closeMenu}>
                      {subItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;