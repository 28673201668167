import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Star, ChevronRight, Check, Building, Award, Calendar, ExternalLink, MapPin } from "lucide-react";
import CalendlyPopupButton from "../calendly/calendly";
import './realestate.css';
import logos from '../../assets/images/developers.png';

export default function Realstate() {
    // Example properties to showcase (you would replace these with actual data)
    const featuredProperties = [
        { id: 1, location: "Downtown Dubai", type: "Apartamento", price: "USD 1.2M", sqm: 120 },
        { id: 2, location: "Palm Jumeirah", type: "Ático", price: "USD 3.5M", sqm: 250 },
        { id: 3, location: "Dubai Marina", type: "Penthouse", price: "USD 2.8M", sqm: 180 },
    ];

    // Partner logos would be imported images in a real implementation

    return (
        <div className="luxury-real-estate">
            <Helmet>
                <title>Invierte en el Mercado Inmobiliario de Dubai - Inversiones Dubai</title>
                <meta name="description" content="Invierte en bienes raíces en Dubái con la asesoria de Diego Carranza. Te brinda opciones, precios, metraje cuadrado y muchas cosas más." />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>

            {/* Hero Section */}
            <div className="hero-section">
                <div className="hero-content">
                    <h2 className="hero-subtitle"><Star className="icon-star" size={24} /> OPORTUNIDAD EXCLUSIVA</h2>
                    <h1 className="hero-title">INVIERTE AHORA EN <span className="accent">DUBAI</span></h1>
                    <p className="hero-description">Diego Carranza, reconocido inversionista, te guía hacia las mejores oportunidades inmobiliarias en el epicentro del lujo global</p>
                    <div className="hero-cta">
                        <CalendlyPopupButton />
                    </div>
                </div>
            </div>

            {/* Introduction Section */}
            <div className="intro-section">
                <h1 className="intro-title">INVIERTE EN <span className="accent">BIENES RAÍCES</span> Y OBTÉN TU PRIMER <span className="accent">INMUEBLE EN DUBÁI</span></h1>
                <p className="intro-subtitle">Acceso privilegiado al mercado inmobiliario más dinámico del mundo</p>
            </div>

            {/* Main Content */}
            <div className="content-container">
                <div className="content-card">
                    <div className="card-header">
                        <p className="card-intro">¿Estás planeando invertir en bienes raíces en Dubái? Desde Inversiones Dubái no solo te ayudaré a encontrar la propiedad perfecta, sino que también te asesoraré sobre el proceso de compra, el papeleo y todo lo que necesitas saber para hacer de tu compra un éxito.</p>
                    </div>

                    {/* Property Options Section */}
                    <div className="content-section">
                        <h2 className="section-title">
                            <span className="accent-line"></span>
                            BENEFICIOS DE INVERTIR EN DUBAI
                            <span className="accent-line"></span>
                        </h2>

                        <div className="benefits-grid">
                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <Check size={32} className="icon-check" />
                                </div>
                                <h3 className="benefit-title">0% DE IMPUESTOS</h3>
                                <p className="benefit-text">Sin impuestos sobre la renta ni ganancias de capital</p>
                            </div>

                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <Building size={32} className="icon-building" />
                                </div>
                                <h3 className="benefit-title">ALTA RENTABILIDAD</h3>
                                <p className="benefit-text">Retorno de inversión superior al 8% anual</p>
                            </div>

                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <Award size={32} className="icon-award" />
                                </div>
                                <h3 className="benefit-title">SEGURIDAD JURÍDICA</h3>
                                <p className="benefit-text">Marco legal estable para inversores extranjeros</p>
                            </div>
                        </div>
                    </div>

                    {/* Featured Properties - Placeholder for images */}
                    <div className="featured-properties">
                        <h2 className="section-title">
                            <span className="accent-line"></span>
                            PROPIEDADES DESTACADAS
                            <span className="accent-line"></span>
                        </h2>

                        <div className="properties-showcase">
                            {featuredProperties.map(property => (
                                <div key={property.id} className="property-card">
                                    <div className="property-image">
                                        {/* Placeholder for property image */}
                                        <div className="image-placeholder"></div>
                                    </div>
                                    <div className="property-details">
                                        <h3 className="property-location">
                                            <MapPin size={16} className="icon-map" /> {property.location}
                                        </h3>
                                        <p className="property-type">{property.type}</p>
                                        <div className="property-specs">
                                            <span className="property-price">{property.price}</span>
                                            <span className="property-size">{property.sqm} m²</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Investment Comparison */}
                    <div className="content-section">
                        <h2 className="section-title">
                            <span className="accent-line"></span>
                            COMPARATIVA DE INVERSIÓN
                            <span className="accent-line"></span>
                        </h2>

                        <div className="comparison-container">
                            <div className="comparison-column">
                                <h3 className="comparison-title accent">PROPIEDADES SOBRE PLANO</h3>
                                <ul className="comparison-list">
                                    <li><Check size={16} className="icon-check" /> Mayor variedad de unidades disponibles</li>
                                    <li><Check size={16} className="icon-check" /> Precios más competitivos</li>
                                    <li><Check size={16} className="icon-check" /> Planes de pago flexibles durante la construcción</li>
                                    <li><Check size={16} className="icon-check" /> Mayor potencial de valorización</li>
                                    <li><Check size={16} className="icon-check" /> Posibilidad de personalización</li>
                                </ul>
                            </div>

                            <div className="comparison-column">
                                <h3 className="comparison-title accent">PROPIEDADES TERMINADAS</h3>
                                <ul className="comparison-list">
                                    <li><Check size={16} className="icon-check" /> Inspección física inmediata</li>
                                    <li><Check size={16} className="icon-check" /> Generación inmediata de ingresos por alquiler</li>
                                    <li><Check size={16} className="icon-check" /> Mayor facilidad para obtener hipotecas</li>
                                    <li><Check size={16} className="icon-check" /> Sin riesgo de retrasos en la construcción</li>
                                    <li><Check size={16} className="icon-check" /> Claridad en los cargos por servicio</li>
                                </ul>
                            </div>
                        </div>

                        <div className="content-text">
                            <p>Cuando se trata de bienes raíces listas para la venta en Dubái, los compradores se benefician al saber exactamente cómo es la propiedad, en contraste con las propiedades sobre plano.</p>
                            <p>Además, los inversores que compran un inmueble o propiedad en Dubai lista, pueden comenzar a <Link to="/" className="text-link">generar ingresos</Link> de alquiler de inmediato al arrendar la unidad. Los compradores, también pueden obtener una mayor relación préstamo-valor (LTV) para propiedades listas, lo que facilita obtener una hipoteca del banco.</p>
                            <p>Por otro lado, aquellos interesados en invertir en inmuebles o bienes raises sobre plano en Dubái tienen una mayor variedad de unidades para elegir dentro de una torre o comunidad, especialmente si el proyecto se acaba de lanzar. Luego, los compradores pueden bloquear unidades principales, que probablemente generarán mayores rendimientos una vez que se completen.</p>
                        </div>
                    </div>

                    {/* Investment Considerations */}
                    <div className="content-section">
                        <h2 className="section-title">
                            <span className="accent-line"></span>
                            FACTORES CLAVE PARA INVERSIONISTAS
                            <span className="accent-line"></span>
                        </h2>

                        <div className="factors-grid">
                            <div className="factor-card">
                                <span className="factor-number">01</span>
                                <h3 className="factor-title">Análisis de mercado competitivo</h3>
                                <p className="factor-text">Verificación de precios de venta actuales de propiedades similares en el mercado.</p>
                            </div>

                            <div className="factor-card">
                                <span className="factor-number">02</span>
                                <h3 className="factor-title">Potencial de ingresos por alquiler</h3>
                                <p className="factor-text">Evaluación de los precios de alquiler en la zona para calcular el retorno de la inversión.</p>
                            </div>

                            <div className="factor-card">
                                <span className="factor-number">03</span>
                                <h3 className="factor-title">Verificación de cargos por servicio</h3>
                                <p className="factor-text">Análisis de los costos recurrentes asociados con la propiedad.</p>
                            </div>

                            <div className="factor-card">
                                <span className="factor-number">04</span>
                                <h3 className="factor-title">Tarifas y transferencias</h3>
                                <p className="factor-text">Comprensión de los costos administrativos y legales del proceso de compra.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Partners Section - Placeholder for partner logos */}
                <div className="partners-section">
                    <h2 className="partners-title">NUESTROS SOCIOS ESTRATÉGICOS</h2>

                    <img src={logos} alt="logos" className="logos" />

                </div>

                {/* CTA Section */}
                <div className="cta-section">
                    <h3 className="cta-title">RESERVE SU CONSULTA <span className="accent">EXCLUSIVA</span> CON DIEGO CARRANZA</h3>
                    <p className="cta-text">Únase a los inversores visionarios que están transformando su patrimonio a través del mercado inmobiliario más dinámico del mundo.</p>
                    <div className="cta-features">
                        <div className="cta-feature">
                            <Calendar size={20} className="icon-calendar" />
                            <span>Consulta personalizada</span>
                        </div>
                        <div className="cta-feature">
                            <Check size={20} className="icon-check" />
                            <span>Acceso a propiedades exclusivas</span>
                        </div>
                        <div className="cta-feature">
                            <Award size={20} className="icon-award" />
                            <span>Asesoría premium</span>
                        </div>
                    </div>
                    <a href="https://wa.link/rdww5u" rel="noreferrer" target="_blank" className="cta-button">
                        DESCUBRE COMO <ChevronRight size={20} className="icon-chevron" />
                    </a>
                </div>
            </div>
        </div>
    );
}