import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import './taxes.css';

// Import images
import entrepreneurImage from '../../assets/images/entrepeneur.webp';
import dubaiBg from '../../assets/images/bg-sections.png';

// Import components
import CalendlyPopupButton from "../calendly/calendly";

// Import Lucide icons
import { 
  TrendingUp, 
  Building, 
  Briefcase, 
  Landmark, 
  Shield, 
  Plane, 
  CreditCard, 
  DollarSign, 
  Home, 
  Globe, 
  Award, 
  Calendar 
} from "lucide-react";

export default function Taxes() {
    // Benefits data
    const benefits = [
        {
            id: 1,
            icon: <DollarSign size={36} />,
            title: "0% Impuesto sobre la Renta",
            description: "Disfrute de exención total de impuestos sobre ingresos personales y corporativos."
        },
        {
            id: 2,
            icon: <Shield size={36} />,
            title: "Seguridad y Estabilidad",
            description: "Uno de los sistemas financieros más seguros y estables del mundo."
        },
        {
            id: 3,
            icon: <Home size={36} />,
            title: "Residencia Permanente",
            description: "Obtenga residencia para usted y su familia con inversiones calificadas."
        },
        {
            id: 4,
            icon: <Globe size={36} />,
            title: "Conexiones Globales",
            description: "Acceso a uno de los aeropuertos internacionales más conectados del mundo."
        }
    ];

    // Investment options data
    const investmentOptions = [
        {
            id: 1,
            icon: <Building size={36} />,
            title: "Bienes Raíces Premium",
            description: "Inversiones en propiedades exclusivas con altos rendimientos anuales."
        },
        {
            id: 2,
            icon: <Briefcase size={36} />,
            title: "Free Zones Empresariales",
            description: "Establezca su empresa con 100% de propiedad extranjera y beneficios fiscales."
        },
        {
            id: 3,
            icon: <Landmark size={36} />,
            title: "Fondos de Inversión",
            description: "Acceda a fondos especializados en sectores estratégicos de alto crecimiento."
        }
    ];

    return (
        <div className="luxury-container">
            <Helmet>
                <title>Invierte en Dubai sin pagar impuestos - Inversiones Dubai</title>
                <meta name="description"
                      content="Si buscas invertir en Dubai ahorrando en Impuestos, Tasas arancelarias, etc... Diego Carranza te ayuda con la asesoria de Inversiones para evitar pagar más dinero."/>
                <link rel="canonical" href="https://inversionesdubai.com"/>
                <meta name="robots" content="index, follow"/>
                <link rel="preload"
                      href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp"
                      as="image" type="image" crossorigin="anonymous"/>
                <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap" rel="stylesheet"/>
            </Helmet>
            
            {/* Hero Section */}
            <div className="hero-section" style={{backgroundImage: `url(${dubaiBg})`}}>
                <div className="hero-content">
                    <h1 className="hero-title">
                        <span className="accent-text">INVIERTE</span> EN DUBAI
                    </h1>
                    <p className="hero-subtitle">Maximiza tu patrimonio con estrategias fiscales exclusivas</p>
                    <div className="hero-cta">
                        <CalendlyPopupButton className="primary-btn" />
                    </div>
                </div>
            </div>

            {/* Introduction Section */}
            <section className="intro-section">
                <div className="section-container">
                    <h2 className="section-title">
                        <span className="accent-text">ESTRATEGIAS DE ÉLITE</span> PARA INVERSIÓN LIBRE DE IMPUESTOS
                    </h2>
                    <p className="section-description">
                        Diego Carranza, reconocido inversionista internacional, te guía en el exclusivo mercado de Dubai con métodos probados y estrategias personalizadas para maximizar tu capital.
                    </p>
                </div>
            </section>

            {/* Benefits Section */}
            <section className="benefits-section">
                <div className="section-container">
                    <h2 className="section-title">Ventajas Fiscales <span className="accent-text">Excepcionales</span></h2>
                    <div className="benefits-grid">
                        {benefits.map(benefit => (
                            <div className="benefit-card" key={benefit.id}>
                                <div className="benefit-icon">{benefit.icon}</div>
                                <h3 className="benefit-title">{benefit.title}</h3>
                                <p className="benefit-description">{benefit.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Investment Options */}
            <section className="investment-section">
                <div className="section-container">
                    <h2 className="section-title">Oportunidades de <span className="accent-text">Inversión Premium</span></h2>
                    <div className="investment-grid">
                        {investmentOptions.map(option => (
                            <div className="investment-card" key={option.id}>
                                <div className="investment-icon">{option.icon}</div>
                                <h3 className="investment-title">{option.title}</h3>
                                <p className="investment-description">{option.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Information Section */}
            <section className="info-section">
                <div className="section-container">
                    <div className="info-grid">
                        <div className="info-content">
                            <h2 className="info-title">¿Por qué <span className="accent-text">Dubai</span> es el destino ideal para inversores?</h2>
                            <p className="info-text">
                                Emiratos Árabes Unidos se ha consolidado como el centro financiero más prominente del Golfo Pérsico, destacándose por su infraestructura vanguardista, estabilidad política y un ecosistema fiscal diseñado para atraer inversión internacional.
                            </p>
                            <p className="info-text">
                                Como experto en inversiones internacionales, proporciono asesoramiento integral basado en la normativa gubernamental de EAU, siempre desde una perspectiva legal y estratégica, maximizando sus beneficios fiscales.
                            </p>
                            <p className="info-text">
                                Las <span className="accent-text">"free trade zones"</span> de Dubai ofrecen un marco regulatorio excepcional diseñado específicamente para atraer inversión extranjera, facilitar la creación de empresas y garantizar la seguridad jurídica de su capital.
                            </p>
                        </div>
                        <div className="info-links">
                            <div className="info-link-card">
                                <TrendingUp size={24} />
                                <h3>Inversiones Estratégicas</h3>
                                <p>Descubra las oportunidades más rentables en el mercado actual.</p>
                                <Link to="/inversiones-estrategicas" className="link-button">Explorar Opciones</Link>
                            </div>
                            <div className="info-link-card">
                                <Plane size={24} />
                                <h3>Residencia Permanente</h3>
                                <p>Obtenga residencia para usted y su familia a través de inversiones calificadas.</p>
                                <Link to="/obtener-residencia-dubai" className="link-button">Conocer Requisitos</Link>
                            </div>
                            <div className="info-link-card">
                                <CreditCard size={24} />
                                <h3>Estructura Fiscal</h3>
                                <p>Optimice su estructura fiscal y elimine cargas impositivas innecesarias.</p>
                                <Link to="/inversiones-impuestos-dubai" className="link-button">Ver Beneficios</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="cta-section">
                <div className="section-container">
                    <div className="cta-content">
                        <h2 className="cta-title">
                            TRANSFORME SU FUTURO FINANCIERO
                            <span className="accent-text"> SIN PAGAR IMPUESTOS</span>
                        </h2>
                        <p className="cta-text">
                            Únase a la élite de inversores internacionales que están maximizando su patrimonio en una de las economías más prósperas del mundo.
                        </p>
                        <a href="https://wa.link/rdww5u" className="cta-button" target="_blank" rel="noopener noreferrer">
                            AGENDE SU CONSULTA EXCLUSIVA
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
}