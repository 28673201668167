import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { 
  Building2, Award, TrendingUp, Shield, ChevronRight, 
  BarChart2, MousePointer, Briefcase, Globe, DollarSign, 
  CheckCircle, Users, Map, Calendar, ArrowRight, X, Menu
} from "lucide-react";
import styles from "./Emigrant.module.css";

import entrepreneurImage from '../../assets/images/entrepeneur.webp';
import CalendlyPopupButton from "../calendly/calendly";

export default function BusinessSetup() {
    const [activeSection, setActiveSection] = useState("intro");
    const [activeFaqIndex, setActiveFaqIndex] = useState(null);
    const [activeBusinessType, setActiveBusinessType] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [animateCounter, setAnimateCounter] = useState(false);
    
    const sectionRefs = {
        intro: useRef(null),
        businessTypes: useRef(null),
        benefits: useRef(null),
        process: useRef(null),
        testimonials: useRef(null)
    };
    
    // Intersection Observer for section animations
    useEffect(() => {
        const observers = {};
        const observerOptions = { threshold: 0.3 };
        
        Object.entries(sectionRefs).forEach(([key, ref]) => {
            if (ref.current) {
                observers[key] = new IntersectionObserver(
                    (entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                setActiveSection(key);
                                if (key === "benefits") {
                                    setAnimateCounter(true);
                                }
                            }
                        });
                    },
                    observerOptions
                );
                observers[key].observe(ref.current);
            }
        });

        return () => {
            Object.values(observers).forEach(observer => {
                observer.disconnect();
            });
        };
    }, []);
    
    // Business setup types
    const businessTypes = [
        {
            title: "Free Zone",
            icon: <Building2 size={32} />,
            description: "Ideal para empresas con operaciones internacionales. 100% de propiedad extranjera y exención fiscal total.",
            benefits: [
                "100% propiedad extranjera",
                "Exención total de impuestos",
                "Repatriación de capital y beneficios",
                "Sin restricciones de divisas"
            ],
            cta: "Establecer Free Zone"
        },
        {
            title: "Mainland",
            icon: <TrendingUp size={32} />,
            description: "Perfecto para negocios que desean operar en todo el territorio de Dubai sin restricciones comerciales.",
            benefits: [
                "Comercio sin restricciones en UAE",
                "Acceso a licitaciones gubernamentales",
                "Sin limitaciones geográficas",
                "Múltiples visados de residencia"
            ],
            cta: "Establecer Mainland"
        },
        {
            title: "Offshore",
            icon: <Shield size={32} />,
            description: "Solución óptima para holding companies y protección de activos con máxima privacidad.",
            benefits: [
                "Máxima confidencialidad",
                "Protección de activos",
                "Estructura fiscal optimizada",
                "Sin necesidad de presencia física"
            ],
            cta: "Establecer Offshore"
        }
    ];
    
    // Setup process steps
    const setupSteps = [
        {
            icon: <MousePointer size={24} />,
            title: "Consulta Inicial",
            description: "Análisis personalizado de tu modelo de negocio para determinar la estructura óptima."
        },
        {
            icon: <Briefcase size={24} />,
            title: "Selección de Actividad",
            description: "Definición de las actividades comerciales de tu empresa según la normativa de Dubai."
        },
        {
            icon: <Map size={24} />,
            title: "Ubicación Estratégica",
            description: "Elección de la mejor zona o jurisdicción para tu negocio según tus objetivos."
        },
        {
            icon: <DollarSign size={24} />,
            title: "Planificación Financiera",
            description: "Estructuración fiscal óptima para maximizar tu rentabilidad empresarial."
        },
        {
            icon: <Globe size={24} />,
            title: "Registro y Licencias",
            description: "Gestión completa del proceso de documentación y obtención de licencias."
        },
        {
            icon: <Users size={24} />,
            title: "Residencia y Visados",
            description: "Tramitación de visados para ti y tu equipo con acceso a la Golden Visa."
        }
    ];
    
    // Frequently asked questions
    const faqs = [
        {
            question: "¿Cuánto tiempo tarda el proceso de apertura de empresa en Dubai?",
            answer: "Generalmente, el proceso completo desde la consultoría inicial hasta la obtención de licencias toma entre 1 y 3 semanas, dependiendo del tipo de empresa y jurisdicción seleccionada."
        },
        {
            question: "¿Necesito visitar Dubai para abrir mi empresa?",
            answer: "No es necesario. Nuestro equipo puede gestionar todo el proceso remotamente, incluyendo la apertura de cuenta bancaria con bancos asociados."
        },
        {
            question: "¿Cuál es la inversión mínima para abrir una empresa en Dubai?",
            answer: "La inversión inicial varía según el tipo de empresa, pero generalmente comienza desde $5,000 USD para empresas offshore, y desde $10,000 USD para Free Zones o Mainland."
        },
        {
            question: "¿Puedo obtener la residencia en Dubai al abrir una empresa?",
            answer: "Sí, los propietarios de empresas en Dubai pueden solicitar visados de residencia para ellos y sus familias. Con inversiones cualificadas, también se puede acceder a la Golden Visa de 10 años."
        }
    ];
    
    // Testimonials
    const testimonials = [
        {
            name: "Carlos Rodríguez",
            company: "Fintech Solutions",
            text: "Gracias a la asesoría de Diego logramos establecer nuestra fintech en DIFC con una estructura fiscal óptima. El proceso fue rápido y sin complicaciones.",
            rating: 5
        },
        {
            name: "María González",
            company: "Global Trading Corp",
            text: "Establecimos nuestra empresa offshore con Diego y ahora operamos con total libertad fiscal. Su conocimiento del mercado de Dubai es incomparable.",
            rating: 5
        },
        {
            name: "Javier Martínez",
            company: "Tech Innovations LLC",
            text: "La asesoría para establecer nuestra empresa en Dubai Silicon Oasis fue impecable. Ahora disfrutamos de 0% de impuestos y acceso a todo el mercado de Oriente Medio.",
            rating: 5
        }
    ];
    
    // Animation counter for statistics
    const Counter = ({ end, duration = 2000, prefix = "", suffix = "" }) => {
        const [count, setCount] = useState(0);
        const countRef = useRef(null);
        
        useEffect(() => {
            if (animateCounter && countRef.current) {
                let startTime;
                const startValue = 0;
                const endValue = end;
                
                const animateCount = (timestamp) => {
                    if (!startTime) startTime = timestamp;
                    const progress = Math.min((timestamp - startTime) / duration, 1);
                    const currentCount = Math.floor(progress * (endValue - startValue) + startValue);
                    setCount(currentCount);
                    
                    if (progress < 1) {
                        requestAnimationFrame(animateCount);
                    }
                };
                
                requestAnimationFrame(animateCount);
            }
        }, [animateCounter, end, duration]);
        
        return (
            <div ref={countRef} className={styles.counter}>
                {prefix}{count}{suffix}
            </div>
        );
    };

    // Navigation handler
    const scrollToSection = (sectionKey) => {
        sectionRefs[sectionKey].current?.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
        });
        setShowMobileMenu(false);
    };

    // Toggle mobile menu
    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Abre tu empresa en Dubái con 0% de impuestos - Inversiones Dubai</title>
                <meta name="description" content="Establece tu negocio en Dubai y benefíciate del régimen fiscal más favorable del mundo. Asesoría experta para Free Zones, Mainland y empresas Offshore" />
                <link rel="canonical" href="https://inversionesdubai.com/apertura-empresa" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>

            {/* Mobile menu */}
            {showMobileMenu && (
                <div className={styles.mobileMenu}>
                    <button 
                        className={styles.mobileCloseButton}
                        onClick={() => setShowMobileMenu(false)}
                    >
                        <X size={24} />
                    </button>
                    <button 
                        className={`${styles.mobileNavLink} ${activeSection === "intro" ? styles.activeMobileNavLink : ""}`}
                        onClick={() => scrollToSection("intro")}
                    >
                        Inicio
                    </button>
                    <button 
                        className={`${styles.mobileNavLink} ${activeSection === "businessTypes" ? styles.activeMobileNavLink : ""}`}
                        onClick={() => scrollToSection("businessTypes")}
                    >
                        Tipos de Empresa
                    </button>
                    <button 
                        className={`${styles.mobileNavLink} ${activeSection === "benefits" ? styles.activeMobileNavLink : ""}`}
                        onClick={() => scrollToSection("benefits")}
                    >
                        Ventajas
                    </button>
                    <button 
                        className={`${styles.mobileNavLink} ${activeSection === "process" ? styles.activeMobileNavLink : ""}`}
                        onClick={() => scrollToSection("process")}
                    >
                        Proceso
                    </button>
                    <button 
                        className={`${styles.mobileNavLink} ${activeSection === "testimonials" ? styles.activeMobileNavLink : ""}`}
                        onClick={() => scrollToSection("testimonials")}
                    >
                        Testimonios
                    </button>
                    <CalendlyPopupButton customClass={styles.mobileConsultButton} />
                </div>
            )}

            {/* Hero Section */}
            <div className={styles.heroSection} ref={sectionRefs.intro}>
                <div className={styles.heroContent}>
                    <h1 className={styles.heroTitle}>
                        Establece tu empresa en <span className={styles.heroTitleHighlight}>DUBAI</span>
                    </h1>
                    <p className={styles.heroSubtitle}>Disfruta de la <span className={styles.accent}>libertad fiscal</span> del ecosistema empresarial más dinámico del mundo</p>
                    
                    <div className={styles.heroButtons}>
                        <CalendlyPopupButton customClass={styles.primaryButton} />
                        <button className={styles.secondaryButton} onClick={() => scrollToSection("businessTypes")}>
                            Explorar Opciones <ChevronRight size={18} />
                        </button>
                    </div>
                </div>
                <div className={styles.scrollIndicator} onClick={() => scrollToSection("businessTypes")}>
                    <span className={styles.scrollText}>Descubre más</span>
                    <div className={styles.scrollArrow}></div>
                </div>
            </div>
            
            {/* Business Types Section */}
            <section className={styles.businessTypesSection} ref={sectionRefs.businessTypes}>
                <div className={styles.sectionHeader}>
                    <h2 className={styles.sectionTitle}>Tipos de <span className={styles.accent}>Empresa</span></h2>
                    <p className={styles.sectionSubtitle}>Elige la estructura empresarial que mejor se adapte a tus objetivos de negocio</p>
                </div>
                
                <div className={styles.businessTypeCards}>
                    {businessTypes.map((type, index) => (
                        <div 
                            key={index}
                            className={`${styles.businessTypeCard} ${activeBusinessType === index ? styles.activeBusinessTypeCard : ''}`}
                            onClick={() => setActiveBusinessType(index)}
                        >
                            <div className={styles.businessTypeIcon}>
                                {type.icon}
                            </div>
                            <h3 className={styles.businessTypeTitle}>{type.title}</h3>
                            <p className={styles.businessTypeDescription}>{type.description}</p>
                            <ul className={styles.businessTypeBenefits}>
                                {type.benefits.map((benefit, i) => (
                                    <li key={i} className={styles.businessTypeBenefit}>
                                        <CheckCircle size={16} className={styles.benefitIcon} />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                            <button className={styles.businessTypeCta} onClick={() => scrollToSection("process")}>
                                {type.cta}
                            </button>
                        </div>
                    ))}
                </div>
                
                <div className={styles.businessTypesCompare}>
                    <CalendlyPopupButton customClass={styles.compareButton}>
                        Comparar Opciones <BarChart2 size={18} />
                    </CalendlyPopupButton>
                </div>
            </section>
            
            {/* Benefits Stats Section */}
            <section className={styles.benefitsSection} ref={sectionRefs.benefits}>
                <div className={styles.benefitsOverlay}></div>
                <div className={styles.benefitsContent}>
                    <h2 className={styles.benefitsTitle}>¿Por qué <span className={styles.accent}>Dubai</span> para tu negocio?</h2>
                    
                    <div className={styles.statsGrid}>
                        <div className={styles.statCard}>
                            <Counter end={0} suffix="%" />
                            <h3>Impuesto de Sociedades</h3>
                            <p>En Free Zones y empresas Offshore</p>
                        </div>
                        <div className={styles.statCard}>
                            <Counter end={100} suffix="%" />
                            <h3>Propiedad Extranjera</h3>
                            <p>Control total de tu negocio</p>
                        </div>
                        <div className={styles.statCard}>
                            <Counter end={3} suffix=" días" />
                            <h3>Tiempo de Setup</h3>
                            <p>Proceso rápido y eficiente</p>
                        </div>
                        <div className={styles.statCard}>
                            <Counter end={10} suffix=" años" />
                            <h3>Golden Visa</h3>
                            <p>Residencia de larga duración</p>
                        </div>
                    </div>
                    
                    <div className={styles.strategicAdvantages}>
                        <h3 className={styles.advantagesTitle}>Ventajas Estratégicas</h3>
                        <div className={styles.advantagesGrid}>
                            <div className={styles.advantageCard}>
                                <Globe size={24} className={styles.advantageIcon} />
                                <h4>Hub Comercial Global</h4>
                                <p>Acceso estratégico a mercados de Oriente Medio, Asia y África</p>
                            </div>
                            <div className={styles.advantageCard}>
                                <Shield size={24} className={styles.advantageIcon} />
                                <h4>Estabilidad Política</h4>
                                <p>Entorno seguro y estable para inversiones a largo plazo</p>
                            </div>
                            <div className={styles.advantageCard}>
                                <Building2 size={24} className={styles.advantageIcon} />
                                <h4>Infraestructura Mundial</h4>
                                <p>Sistemas logísticos y tecnológicos de vanguardia</p>
                            </div>
                            <div className={styles.advantageCard}>
                                <Award size={24} className={styles.advantageIcon} />
                                <h4>Prestigio Internacional</h4>
                                <p>Valor añadido a tu marca y oportunidades de networking</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* Process Section */}
            <section className={styles.processSection} ref={sectionRefs.process}>
                <div className={styles.sectionHeader}>
                    <h2 className={styles.sectionTitle}>Proceso <span className={styles.accent}>Simplificado</span></h2>
                    <p className={styles.sectionSubtitle}>Te guiamos en cada paso para establecer tu negocio en Dubai sin complicaciones</p>
                </div>
                
                <div className={styles.processTimeline}>
                    {setupSteps.map((step, index) => (
                        <div key={index} className={styles.processStep}>
                            <div className={styles.processStepNumber}>
                                <span>{index + 1}</span>
                            </div>
                            <div className={styles.processStepContent}>
                                <div className={styles.processStepIcon}>
                                    {step.icon}
                                </div>
                                <h3 className={styles.processStepTitle}>{step.title}</h3>
                                <p className={styles.processStepDescription}>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            
            {/* FAQ Section */}
            <section className={styles.faqSection}>
                <div className={styles.sectionHeader}>
                    <h2 className={styles.sectionTitle}>Preguntas <span className={styles.accent}>Frecuentes</span></h2>
                    <p className={styles.sectionSubtitle}>Resolvemos tus dudas sobre el establecimiento de empresas en Dubai</p>
                </div>
                
                <div className={styles.faqContainer}>
                    {faqs.map((faq, index) => (
                        <div 
                            key={index} 
                            className={`${styles.faqItem} ${activeFaqIndex === index ? styles.activeFaq : ''}`}
                            onClick={() => setActiveFaqIndex(activeFaqIndex === index ? null : index)}
                        >
                            <div className={styles.faqQuestion}>
                                <h3>{faq.question}</h3>
                                <span className={styles.faqToggle}>
                                    {activeFaqIndex === index ? <X size={20} /> : <ChevronRight size={20} />}
                                </span>
                            </div>
                            <div className={`${styles.faqAnswer} ${activeFaqIndex === index ? styles.showFaqAnswer : ''}`}>
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            
            {/* Testimonials Section */}
            <section className={styles.testimonialsSection} ref={sectionRefs.testimonials}>
                <div className={styles.sectionHeader}>
                    <h2 className={styles.sectionTitle}>Empresarios <span className={styles.accent}>Satisfechos</span></h2>
                    <p className={styles.sectionSubtitle}>Historias de éxito de quienes ya establecieron su negocio en Dubai</p>
                </div>
                
                <div className={styles.testimonialsGrid}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className={styles.testimonialCard}>
                            <div className={styles.testimonialRating}>
                                {[...Array(5)].map((_, i) => (
                                    <span key={i} className={i < testimonial.rating ? styles.starFilled : styles.star}>★</span>
                                ))}
                            </div>
                            <p className={styles.testimonialText}>"{testimonial.text}"</p>
                            <div className={styles.testimonialAuthor}>
                                <h4>{testimonial.name}</h4>
                                <p>{testimonial.company}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            
            {/* Final CTA Section */}
            <section className={styles.finalCtaSection}>
                <div className={styles.finalCtaContent}>
                    <h2 className={styles.finalCtaTitle}>¿Listo para establecer tu <span className={styles.accent}>imperio empresarial</span> en Dubai?</h2>
                    <p className={styles.finalCtaText}>
                        Únete a los cientos de empresarios visionarios que ya están aprovechando las ventajas fiscales y 
                        estratégicas de Dubai para llevar sus negocios al siguiente nivel
                    </p>
                    <div className={styles.finalCtaButtons}>
                        <CalendlyPopupButton customClass={styles.primaryButton} />
                        <a href="https://wa.link/rdww5u" className={styles.secondaryButton} target="_blank" rel="noopener noreferrer">
                            Contacto Directo <ArrowRight size={18} />
                        </a>
                    </div>
                </div>
                <div className={styles.legalNote}>
                    <p>© {new Date().getFullYear()} Inversiones Dubai. Asesoría personalizada para establecimiento de empresas y residencia fiscal</p>
                </div>
            </section>
            
        </div>
    );
}