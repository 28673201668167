import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChevronLeft, ChevronRight, Play, Youtube } from 'lucide-react';
import on from '../../assets/images/ON.png';
import styles from './slider.module.css';

const VideoCarousel = () => {
    const data = [
        {
            image: on,
            title: 'TRANSFORMA TU VIDA ASÍ',
            text: 'Abr 14, 2024',
            link: 'https://www.youtube.com/watch?v=ahXZ9P0xtWc'
        },
        {
            image: on,
            title: 'RESTAURANTERO A CONSULTOR',
            text: 'Abr 1, 2024',
            link: 'https://www.youtube.com/watch?v=gxS5cGgXf48&t=6s'
        },
        {
            image: on,
            title: 'EL INTERNET ME SALVÓ LA VIDA',
            text: 'Mar 9, 2024',
            link: 'https://www.youtube.com/watch?v=V1BrnnP4Fow&list=PLkY-1Giup84EJ9eRYazIkMFrNJG4Er7N8&index=3'
        },
        {
            image: on,
            title: 'EL NEGOCIO DEL FUTURO',
            text: 'Jun 7, 2024',
            link: 'https://www.youtube.com/watch?v=lkDcT6adnDk'
        },
    ];

    // Custom arrow components using Lucide React
    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} ${styles.customArrow} ${styles.prevArrow}`} onClick={onClick}>
                <ChevronLeft size={24} />
            </div>
        );
    };

    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={`${className} ${styles.customArrow} ${styles.nextArrow}`} onClick={onClick}>
                <ChevronRight size={24} />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // Show 3 slides on desktop
        slidesToScroll: 1,
        arrows: true,
        centerMode: false,
        adaptiveHeight: true,  // Changed to true for better content handling
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        lazyLoad: 'ondemand',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,  // Show 2 on medium screens
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,  // Show 1 on mobile
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ],
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />
    };

    return (
        <div className={styles.sliderWrapper}>
            <div className={styles.carouselContainer}>
                <Slider {...settings}>
                    {data.map((item, index) => (
                        <div key={index} className={styles.cardWrapper}>
                            <div className={styles.card}>
                                <div className={styles.cardImageContainer}>
                                    <img src={item.image} alt={item.title} className={styles.cardImage} />
                                    <div className={styles.videoIcon}>
                                        <div className={styles.playIconCircle}>
                                            <Play size={24} fill="white" color="white" />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.cardContent}>
                                    <h3 className={styles.cardTitle}>{item.title}</h3>
                                    <p className={styles.cardText}>{item.text}</p>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer" className={styles.buttonRed} aria-label={`Ver video: ${item.title}`}>
                                        DESCUBRELO AQUÍ
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className={styles.youtubeButtonContainer}>
                <a href="https://www.youtube.com/@InversionesDubai" target="_blank" rel="noopener noreferrer" className={styles.buttonYoutube} aria-label="Ver canal de YouTube: Consejos de Inversión">
                    <Youtube size={24} className={styles.youtubeIcon} />
                    CONSEJOS DE INVERSIÓN
                </a>
            </div>
        </div>
    );
};

export default VideoCarousel;