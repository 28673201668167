import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { DollarSign, Briefcase, TrendingUp, Award, Key, MapPin, Calendar, ChevronRight, Clock } from "lucide-react";
import et from '../../assets/images/entrepeneur.webp';
import CalendlyPopupButton from "../calendly/calendly";
import styles from './criptomonedas.module.css';

export default function Cripto() {
    return (
        <div className={styles.cryptoDubaiContainer}>
            <Helmet>
                <title>Invierte en Criptodivisas en Dubai - Inversiones Dubai</title>
                <meta name="description" content="Si quieres invertir tu dinero en criptomonedas y guardarlas en Dubai, diego carranza te asesora para invertir en las crypto con más auge del mercado" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>

            {/* Hero Section */}
            <div className={styles.heroSection}>
                <div className={styles.heroContent}>
                    <h2 className={styles.heroSubtitle}><span className={styles.accent}>INVIERTE AHORA EN</span> DUBAI</h2>
                    <p className={styles.heroDescription}>Diego Carranza, reconocido inversionista, te ayuda a invertir en Dubai de la mejor forma</p>
                    <div className={styles.heroCta}>
                        <CalendlyPopupButton />
                    </div>
                </div>
            </div>

            {/* Main Title Section */}
            <div className={styles.mainTitleSection}>
                <h1 className={styles.mainTitle}>INVIERTE <span className={styles.accent}>EN CRIPTOMONEDAS</span> Y RESGUARDA <span className={styles.accent}>TUS INGRESOS</span> CON MIS MÉTODOS</h1>
            </div>

            {/* Content Card */}
            <div className={styles.luxuryCard}>
                <div className={styles.cardHeader}>
                    <DollarSign className={styles.cardIcon} />
                    <h2 className={`${styles.cardTitle} ${styles.accent}`}>¿Cuál es el proceso de invertir en cripto dubai?</h2>
                </div>
                <div className={styles.cardContent}>
                    <p>Desde hace unos años, el mercado de criptomonedas está en auge y muchas personas están interesadas en invertir en ellas. Sin embargo, el tema de la tributación en este ámbito es un poco complicado y muchas personas no saben por dónde empezar.</p>
                    <p>Según la famosa revista estadounidense «Business Insider», en 2018 se vendieron 50 apartamentos de lujo en Dubái a cambio de Bitcoin, lo que marcó la primera transacción inmobiliaria para una criptomoneda en los Emiratos Árabes Unidos.</p>
                    <p>Si usted está interesado en <span className={styles.accent}>invertir en criptomonedas en Dubái</span>, o quiere saber <Link to="/inversiones-impuestos-dubai" className={styles.luxuryLink}>cómo retirar criptomonedas sin pagar impuestos</Link>, pero no sabe por dónde empezar, yo le puedo ayudar.</p>
                </div>

                <div className={styles.cardHeader}>
                    <TrendingUp className={styles.cardIcon} />
                    <h2 className={`${styles.cardTitle} ${styles.accent}`}>¿Qué ventajas y desventajas hay en Dubai?</h2>
                </div>
                <div className={styles.cardContent}>
                    <div className={styles.subSection}>
                        <h3 className={styles.cardSubtitle}><Clock className={styles.subtitleIcon} /> ¿Hay alguna tasa impositiva por sacar criptomonedas en Dubai?</h3>
                        <p>Puede comprar criptomonedas en los Emiratos Árabes Unidos de la misma manera que compra Bitcoin. No tienes que pagar impuestos extra, solamente los feed de la empresa que realizan el cambio de criptomonedas a dinero fiat.</p>
                    </div>
                    
                    <div className={styles.subSection}>
                        <h3 className={styles.cardSubtitle}><Briefcase className={styles.subtitleIcon} /> Si tengo muchas criptomonedas en mi país, ¿Me sale rentable sacarlas en Dubai?</h3>
                        <p>Si tienes criptomonedas en tu país y quieres diversificar ese <Link to="/invertir-bienes-raises-dubai" className={styles.luxuryLink}>dinero realizando inversiones</Link>, Dubái es una de las mejores opciones, ya que en Dubái puedes realizar inversiones inmobiliarias y crear empresas. A diferencia de los países latinos que tratan de penalizar el uso de criptomonedas o tratan de ponerles altos impuestos, Dubái está encaminando a ser una ciudad donde todos puedan tener una billetera virtual y todos puedan tener sus activos digitales.</p>
                    </div>
                </div>

                <div className={styles.cardHeader}>
                    <Key className={styles.cardIcon} />
                    <h2 className={`${styles.cardTitle} ${styles.accent}`}>¿Puedo comprar una propiedad en Dubai con Bitcoin?</h2>
                </div>
                <div className={styles.cardContent}>
                    <p>La propiedad inmobiliaria de Dubái aceptará transacciones con Bitcoin y otras criptomonedas, siempre que se sigan las leyes correspondientes. Para muchos inversores, Dubái y el resto de los Emiratos Árabes Unidos son actualmente la criptociudad más popular, así como una de las opciones más populares para los inversores que buscan <Link to="/invertir-bienes-raises-dubai" className={styles.luxuryLink}>comprar bienes raíces en el país</Link>.</p>
                    
                    <div className={styles.subSection}>
                        <h3 className={styles.cardSubtitle}><MapPin className={styles.subtitleIcon} /> ¿Se puede comprar una propiedad con Bitcoin en Dubai?</h3>
                        <p>Sí; Es posible comprar una propiedad utilizando las criptomonedas «Bitcoin» en Dubái, pero no todos los vendedores aceptarían una oferta de moneda digital y, si lo hacen, es posible que solo acepten tipos de criptomonedas conocidas como Bitcoin o Ethereum.</p>
                    </div>
                    
                    <div className={styles.subSection}>
                        <h3 className={styles.cardSubtitle}>Compre y venda bienes raíces en Dubai con criptomoneda Bitcoin</h3>
                        <p>Hace unos años, era inimaginable comprar un terreno con una moneda digital unificada, pero hoy en día, es la tarea más fácil de hacer. Como todo el mundo está cambiando su paradigma a digital, no es una sorpresa que la moneda también se digitalice. Hoy en día, los avances tecnológicos le permiten <Link to="/invertir-bienes-raises-dubai" className={styles.luxuryLink}>comprar bienes raíces</Link> con criptomonedas donde los métodos son más seguros que los medios tradicionales para comprar una propiedad.</p>
                        <p>Te ofrezco un círculo completo de servicios en compra y venta de propiedades en Dubái con criptomonedas. Desde casas, palacios, hasta apartamentos. Con la creciente necesidad de cambiar a medios de vida digitales, me enorgullezco de presentar el servicio para comprar y vender propiedades en Dubái.</p>
                    </div>
                    
                    <div className={styles.subSection}>
                        <h3 className={styles.cardSubtitle}><Calendar className={styles.subtitleIcon} /> ¿Cuál es el proceso de transacción de la criptomoneda?</h3>
                        <p>El proceso de compra en Emiratos Árabes Unidos es bastante sencillo. Primero, póngase en contacto conmigo. para encontrar la propiedad que más le guste. Luego confirme la compra y transfiera el dinero en criptomonedas. Después de eso, yo me encargaré del resto, la gestión y la compra del inmueble. Eso es todo, la propiedad está comprada.</p>
                    </div>
                </div>

                <div className={styles.cardHeader}>
                    <DollarSign className={styles.cardIcon} />
                    <h2 className={`${styles.cardTitle} ${styles.accent}`}>¿A partir de cuantos € o $ me puede convenir ir a Dubai para convertir mis criptomonedas en dinero Fiat?</h2>
                </div>
                <div className={styles.cardContent}>
                    <p>Usualmente, los latinos prefieren realizar las transferencias en criptomonedas y convertirlas en dinero fiat en Dubái. Para la cantidad no hay límite y las comisiones suelen ser muy bajas para este tipo de transacción.</p>
                    <p>Por otro lado, usted puede comprar propiedades desde 140 mil USD, o puede entrar a formas de pago en dólares sin interés, con tan solo abonar el 10 % del valor del proyecto (en caso de ser estudios puede rondar los 15 mil USD), lo otro que tiene que tener en cuenta, es que ya no es necesario viajar a Dubái para poder comprar una propiedad.</p>
                </div>
            </div>

            {/* CTA Section */}
            <div className={styles.ctaSection}>
                <div className={styles.ctaContent}>
                    <Award className={styles.ctaIcon} />
                    <h3 className={styles.ctaTitle}>INVIERTE EN <span className={styles.accent}>CRIPTO AHORA</span> Y NO GUARDES DINERO "DEBAJO DEL COLCHÓN"</h3>
                    <p className={styles.ctaText}>Sé parte de las muchas personas que se suman diariamente a cambiar su vida y empezar a vivir en una de las ciudades más ricas del mundo.</p>
                    <a href="https://wa.link/rdww5u" className={styles.ctaButton}>
                        <span>AGENDA TU REUNIÓN AQUÍ</span>
                        <ChevronRight className={styles.buttonIcon} />
                    </a>
                </div>
            </div>
        </div>
    );
}